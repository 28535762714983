<template>
  <div class="yogi-ptsal">
    <!-- ok-only
    ok-title="Accept" -->
    <b-modal
      id="modal-select-vessel"
      centered
      hide-footer
      size="xl"
      title=""
      hide
      hide-backdrop
    >
      <SelectVesselComp @select-vessel="selectVessel"/>
      <!-- <b-card-text>Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly.</b-card-text> -->
    </b-modal>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <span>
          <b-button
            class="mt-1"
            block
            variant="outline-primary"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-select-vessel
            >
            <!-- @click="handleChooseVessel()" -->
            <feather-icon
              icon="AnchorIcon"
              :style="{ cursor: 'pointer' }"
              class="mr-50"
            />
            <span class="align-middle" v-text="'Select Vessel Identity'" />
            <b-spinner
              :key="1"
              :variant="summary != null ? 'success' : 'warning'"
              class="ml-1"
              type="grow"
              small
            />
          </b-button>
        </span>
        <span>
          <h3>
            #Form
            <b-badge :variant="dataID ? 'light-danger' : 'primary'">
              {{ dataID ? 'Ubah' : 'Terbitkan' }}
            </b-badge>
            - Pengajuan Pelayanan Jasa
          </h3>
        </span>
      </div>
    </b-card>

    <b-card v-if="summary">
      <b-img
        lazy
        src="boat2.png"
        class="congratulation-medal image"
        height="130"
        style="opacity: 0.5"
      />
      <table class="mt-2 mt-xl-0 w-100">
        <tr>
          <th class="pb-50">
            <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
            <span class="font-weight-bold">
              {{ $t('Nama Kapal') }}
            </span>
          </th>
          <td class="pb-50">
            <span @click="copyText(summary?.raw_data?.boat_name)">{{ summary?.raw_data?.boat_name ?? '' }}</span> ⚓️ <span @click="copyText(summary?.raw_data?.name_tug_boat)">{{ summary?.raw_data?.name_tug_boat ?? '' }}</span>
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
            <span class="font-weight-bold">
              {{ $t('Nomor PKK') }}
            </span>
          </th>
          <td class="pb-50">
            <span @click="copyText(summary?.raw_data?.no_pkk)">{{ summary?.raw_data?.no_pkk ?? '' }}</span> ⚓️ <span @click="copyText(summary?.raw_data?.no_pkk_tug_boat)">{{ summary?.raw_data?.no_pkk_tug_boat }}</span>
          </td>
        </tr>
        <tr>
          <th class="pb-50">
            <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
            <span class="font-weight-bold">
              Diterbitkan Oleh
            </span>
          </th>
          <td class="pb-50">
            <strong>{{ summary?.raw_data?.user.full_name}}</strong> From <strong>{{ summary?.raw_data?.user.perusahaan}}</strong> AT <strong>{{ formatDateTime(summary?.raw_data?.user.created_at) }}</strong>
          </td>
        </tr>
      </table>
    </b-card>
    <b-card>
      <b-form-group
        label="Rencana / Uraian Kegiatan"
        label-for="lbl-Rencana Uraian Kegiatan"
      >
        <validation-provider
          #default="{ errors }"
          name="Rencana Uraian Kegiatan"
          vid="Rencana Uraian Kegiatan"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="TrelloIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="lbl-Rencana Uraian Kegiatan"
              v-model="form.rencana_uraian_keg"
              :state="errors.length > 0 ? false : null"
              :placeholder="''"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <h3>Upload Document File | Manifest</h3>
      <small>max size 10 MB ( xls, xlsx, pdf , jpeg , jpg , png )</small>
      <b-spinner v-if="fetchLoading" small></b-spinner>
      <DropZoneUploadJOPS
        v-else
        :key="isHaveFile.length"
        :uri="BACKEND_URL"
        :update-i-d="dataID"
        accept-file="application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx"
        :max-files="10"
        re-upload-title="Re Upload Pengajuan Jasa OPS  (Max 10 File Excel)"
        :is-have-file="isHaveFile"
        :modelName="'jops_pengajuan_uploads'"
        info="Maksimal 10 Berkas"
        :disable-action="true"
        @callBack="dropZoneCallBack"
      />
    </b-card>
    <b-button
      v-if="files.length !== 0"
      class="mt-1"
      block
      variant="gradient-primary"
      :disabled="fetchLoading"
      @click="handleSubmit()"
    >
      <feather-icon
        v-if="!fetchLoading"
        icon="DatabaseIcon"
        :style="{ cursor: 'pointer' }"
        class="mr-50"
      />
      <b-spinner v-if="fetchLoading" small></b-spinner>
      <span class="align-middle" v-text="'Terbitkan Form Jasa Pelayanan'" />
    </b-button>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import DropZoneUploadJOPS from '@views/components/input/upload/DropZoneUploadJOPS.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SelectVesselComp from "./component/SelectVesselComp.vue";
import Ripple from 'vue-ripple-directive'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardText,
  BAvatar,
  BBadge,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'

export default {
  components: {
    SelectVesselComp,
    DropZoneUploadJOPS,
    BAvatar,
    BBadge,
    Indonesian,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardActions,
    BCardText,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      errorCatch: null,
      errorPath: null,
      errorCatch: null,
      errorMatchValue: null,
      errorValue: null,

      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      isHaveFile: [],
      files: [],
      dataID: this.$router.currentRoute.params.id,
      jops_pengajuan_dt: this.$router.currentRoute.params.jops_pengajuan_dt,
      jops_pengajuan_dt_id:
        this.$router.currentRoute.params.jops_pengajuan_dt_id,
      fetchLoading: false,
      fetchLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      summary : null,
      form: {
        rencana_uraian_keg: '',
        catatan: ''
      },
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: false,
        // altInput: true,
        // dateFormat: 'Y-m-d H:i',
        altInput: true,
        altFormat: 'j F Y - h:i K',
        // altFormat: 'j F Y - h:m K',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: false,
          dateFormat: 'Y-m-d H:i',
          locale: Indonesian,
          minDate: null
        }
      }
    }
  },
  watch: {
    errorCatch() {
      try {
        const [firstKey] = Object.keys(this.errorCatch.response.data.message) // by keys
        const [firstValue] = Object.values(
          this.errorCatch.response.data.message
        )
        this.errorMatchValue =
          this.errorCatch.response.data.message.value || null // by values
        this.errorPath = firstKey
        this.errorValue = firstValue
      } catch (error) {
        console.log('watch error catch error : ', error)
        console.log('watch error catch error : ', this.errorCatch)
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    formatDateTime(value, format = 'D MMMM YYYY - HH:mm') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    selectVessel(raw_data){
      this.summary = raw_data
      console.log(raw_data)
      this.$bvModal.hide('modal-select-vessel'); // Close the modal
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.fetchLoading = false
    },
    isTimerStart() {
      this.fetchLoading = true
      this.yoTimer = setInterval(() => {
        if (this.fetchLoadingValue <= 75 && this.fetchLoading >= 0) {
          this.fetchLoadingValue += 25
        } else {
          this.fetchLoadingValue = 0
        }
      }, 1000)
    },
    async uploadFile() {
      const formData = new FormData()
      const uriAction = `jops_pengajuan_upload/null/false`
      this.files.forEach((file) => {
        if (file instanceof File) {
          formData.append('file', file)
        } else {
          console.log(`form data else : ${file}`)
        }
      })
      useJwt.http
        .put(uriAction, formData)
        .then(async (response) => {
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })

          let payload = {
            ...this.form,
            vessel_api: this.summary.api,
            vessel_id: this.summary.id,
            vessel_name: this.summary.raw_data.boat_name,
            vessel_name_tb: this.summary.raw_data.name_tug_boat,
            file_ids: response.data.inserted_ids
          }

          await useJwt.http
            .post('jops_pengajuan', payload)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'SaveIcon',
                  variant: 'success'
                }
              })
              this.$router.go(-1)
            })
            .catch((error) => {
              this.isTimerDone()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'XCircleIcon',
                  variant: 'danger'
                }
              })
            })
        })
        .catch((err) => {
          console.log(`error ${err}`)
          this.isTimerDone()
          this.errorCatch = err
        })
    },
    async handleSubmit() {
      this.isTimerStart()
      await this.uploadFile()
    },
    dropZoneCallBack(val) {
      console.log('dropZoneCallBack::', val)
      if (val === 'success') {
        // this.fetchData()
        this.filesCheck = null
      } else {
        /*  */
        this.filesCheck = 'have'
        this.files = val
        console.log('this.files::', this.files)
      }
    },
    // getStatusVariant(status) {
    //   switch (status) {
    //     case 'IN-PROGRESS':
    //       return 'warning' // Yellow
    //     case 'COMPLETED':
    //       return 'success' // Green
    //     case 'PENDING':
    //       return 'danger' // Red
    //     case 'PLAN':
    //       return 'primary' // Blue
    //     case 'DAILY':
    //       return 'warning' // Light Blue
    //     case 'JR':
    //       return 'success' // Black/Gray
    //     default:
    //       return 'secondary' // Gray
    //   }
    // },
    // dateHumanReadAble(value) {
    //   const format = moment(value).format('D MMMM YYYY - HH:mm')
    //   if (format === 'Invalid date') {
    //     return '-'
    //   }
    //   return format
    // },
    async fetchData() {
      const dataID = this.dataID
      if (dataID !== undefined) {
        /* edit data */
        this.fetchLoading = true
        await useJwt.http
          .get(`jops_pengajuan_dts_keg/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            const {
              uraian_kegiatan,
              jumlah1,
              jumlah_s1,
              jumlah2,
              jumlah_s2,
              mulai,
              selesai,
              jasa_dermaga,
              jasa_stevodoring,
              jasa_cargodoring,
              lift_on_off,
              note
            } = response.data[`jops_pengajuan_dts_kegs`]
            this.form.uraian_kegiatan = uraian_kegiatan
            this.form.jumlah1 = jumlah1
            this.form.jumlah_s1 = jumlah_s1
            this.form.jumlah2 = jumlah2
            this.form.jumlah_s2 = jumlah_s2
            this.form.mulai = mulai
            this.form.selesai = selesai
            this.form.jasa_dermaga = jasa_dermaga
            this.form.jasa_stevodoring = jasa_stevodoring
            this.form.jasa_cargodoring = jasa_cargodoring
            this.form.lift_on_off = lift_on_off
            this.form.note = note
          })
      }
    }
    // async handleSubmit() {
    //   this.fetchLoading = true
    //   let payload = {
    //     ...this.form,
    //     jops_pengajuan_dt_id: this.jops_pengajuan_dt_id
    //   }

    //   if (this.dataID) {
    //     await useJwt.http
    //       .put(`jops_pengajuan_dts_keg/${this.dataID}`, payload)
    //       .then(() => {
    //         this.fetchLoading = false
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Form Submitted - Updated',
    //             icon: 'SaveIcon',
    //             variant: 'success'
    //           }
    //         })
    //         this.$router.go(-1)
    //       })
    //       .catch((error) => {
    //         this.fetchLoading = false
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Form Submitted - Created',
    //             icon: 'XCircleIcon',
    //             variant: 'danger'
    //           }
    //         })
    //       })
    //   } else {
    //     await useJwt.http
    //       .post('jops_pengajuan_dts_keg', payload)
    //       .then(() => {
    //         this.fetchLoading = false
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Form Submitted',
    //             icon: 'SaveIcon',
    //             variant: 'success'
    //           }
    //         })
    //         this.$router.go(-1)
    //       })
    //       .catch((error) => {
    //         this.fetchLoading = false
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Form Submitted',
    //             icon: 'XCircleIcon',
    //             variant: 'danger'
    //           }
    //         })
    //       })
    //   }
    // },
    // jasaPelayanan(value) {
    //   // Reset all fields to false
    //   this.form.jasa_dermaga = false
    //   this.form.jasa_stevodoring = false
    //   this.form.jasa_cargodoring = false
    //   this.form.lift_on_off = false

    //   // Set the selected one to true
    //   if (value === 1) {
    //     this.form.jasa_dermaga = true
    //   } else if (value === 2) {
    //     this.form.jasa_stevodoring = true
    //   } else if (value === 3) {
    //     this.form.jasa_cargodoring = true
    //   } else if (value === 4) {
    //     this.form.lift_on_off = true
    //   }
    // },
    // onStartChange(selectedDates, dateStr, instance, model) {
    //   console.log('selectedDates', selectedDates)
    //   console.log('dateStr', dateStr)
    //   console.log('instance', instance)
    //   console.log('model', model)
    //   this.configs.end.minDate = dateStr
    // },
    // onEndChange(selectedDates, dateStr, instance) {
    //   this.dpconfig.maxDate = dateStr
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;

#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;

            .vgt-global-search__input {
              border: none !important;

              .input__icon {
                font-weight: 700 !important;
              }

              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }

          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;

            thead {
              th {
                border: $color-yo-border !important;
              }

              /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }

            tbody {
              tr.custom-transition {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-active,
              tr.custom-transition-leave-active {
                transition: opacity 0.5s ease;
              }

              tr.custom-transition-enter-from,
              tr.custom-transition-leave-to {
                opacity: 0;
              }

              th {
                border: $color-yo-border !important;
              }

              /* left checkbox */
              td {
                border: $color-yo-border !important;
              }

              /* right checkbox */
            }
          }

          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }

            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.yogi-ptsal {
  .yo-card {
    &:hover {
      transition: all 0.1s;
      scale: 1.1;
      box-shadow: 0 4px 8px rgb(0, 119, 255);
      opacity: 0.9;
      cursor: pointer;
    }
  }
  .image {
    animation-name: boat;
    animation-duration: 1.5s;
    @keyframes boat {
      0% {
        transform: rotate(40deg);
      }
      25% {
        transform: rotate(95deg);
      }
      50% {
        transform: rotate(-40deg);
      }
    }
    /*scale: 0.2;*/
  }
}
</style>
