var render = function () {
  var _vm$summary$raw_data$, _vm$summary2, _vm$summary2$raw_data, _vm$summary$raw_data$2, _vm$summary4, _vm$summary4$raw_data, _vm$summary$raw_data$3, _vm$summary6, _vm$summary6$raw_data, _vm$summary8, _vm$summary8$raw_data, _vm$summary9, _vm$summary9$raw_data, _vm$summary10, _vm$summary10$raw_dat, _vm$summary11, _vm$summary11$raw_dat;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "yogi-ptsal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-select-vessel",
      "centered": "",
      "hide-footer": "",
      "size": "xl",
      "title": "",
      "hide": "",
      "hide-backdrop": ""
    }
  }, [_c('SelectVesselComp', {
    on: {
      "select-vessel": _vm.selectVessel
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "custom-search d-flex justify-content-between"
  }, [_c('span', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-select-vessel",
      modifiers: {
        "modal-select-vessel": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "block": "",
      "variant": "outline-primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Select Vessel Identity')
    }
  }), _c('b-spinner', {
    key: 1,
    staticClass: "ml-1",
    attrs: {
      "variant": _vm.summary != null ? 'success' : 'warning',
      "type": "grow",
      "small": ""
    }
  })], 1)], 1), _c('span', [_c('h3', [_vm._v(" #Form "), _c('b-badge', {
    attrs: {
      "variant": _vm.dataID ? 'light-danger' : 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.dataID ? 'Ubah' : 'Terbitkan') + " ")]), _vm._v(" - Pengajuan Pelayanan Jasa ")], 1)])])]), _vm.summary ? _c('b-card', [_c('b-img', {
    staticClass: "congratulation-medal image",
    staticStyle: {
      "opacity": "0.5"
    },
    attrs: {
      "lazy": "",
      "src": "boat2.png",
      "height": "130"
    }
  }), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_c('span', {
    on: {
      "click": function click($event) {
        var _vm$summary, _vm$summary$raw_data;
        return _vm.copyText((_vm$summary = _vm.summary) === null || _vm$summary === void 0 ? void 0 : (_vm$summary$raw_data = _vm$summary.raw_data) === null || _vm$summary$raw_data === void 0 ? void 0 : _vm$summary$raw_data.boat_name);
      }
    }
  }, [_vm._v(_vm._s((_vm$summary$raw_data$ = (_vm$summary2 = _vm.summary) === null || _vm$summary2 === void 0 ? void 0 : (_vm$summary2$raw_data = _vm$summary2.raw_data) === null || _vm$summary2$raw_data === void 0 ? void 0 : _vm$summary2$raw_data.boat_name) !== null && _vm$summary$raw_data$ !== void 0 ? _vm$summary$raw_data$ : ''))]), _vm._v(" ⚓️ "), _c('span', {
    on: {
      "click": function click($event) {
        var _vm$summary3, _vm$summary3$raw_data;
        return _vm.copyText((_vm$summary3 = _vm.summary) === null || _vm$summary3 === void 0 ? void 0 : (_vm$summary3$raw_data = _vm$summary3.raw_data) === null || _vm$summary3$raw_data === void 0 ? void 0 : _vm$summary3$raw_data.name_tug_boat);
      }
    }
  }, [_vm._v(_vm._s((_vm$summary$raw_data$2 = (_vm$summary4 = _vm.summary) === null || _vm$summary4 === void 0 ? void 0 : (_vm$summary4$raw_data = _vm$summary4.raw_data) === null || _vm$summary4$raw_data === void 0 ? void 0 : _vm$summary4$raw_data.name_tug_boat) !== null && _vm$summary$raw_data$2 !== void 0 ? _vm$summary$raw_data$2 : ''))])])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_c('span', {
    on: {
      "click": function click($event) {
        var _vm$summary5, _vm$summary5$raw_data;
        return _vm.copyText((_vm$summary5 = _vm.summary) === null || _vm$summary5 === void 0 ? void 0 : (_vm$summary5$raw_data = _vm$summary5.raw_data) === null || _vm$summary5$raw_data === void 0 ? void 0 : _vm$summary5$raw_data.no_pkk);
      }
    }
  }, [_vm._v(_vm._s((_vm$summary$raw_data$3 = (_vm$summary6 = _vm.summary) === null || _vm$summary6 === void 0 ? void 0 : (_vm$summary6$raw_data = _vm$summary6.raw_data) === null || _vm$summary6$raw_data === void 0 ? void 0 : _vm$summary6$raw_data.no_pkk) !== null && _vm$summary$raw_data$3 !== void 0 ? _vm$summary$raw_data$3 : ''))]), _vm._v(" ⚓️ "), _c('span', {
    on: {
      "click": function click($event) {
        var _vm$summary7, _vm$summary7$raw_data;
        return _vm.copyText((_vm$summary7 = _vm.summary) === null || _vm$summary7 === void 0 ? void 0 : (_vm$summary7$raw_data = _vm$summary7.raw_data) === null || _vm$summary7$raw_data === void 0 ? void 0 : _vm$summary7$raw_data.no_pkk_tug_boat);
      }
    }
  }, [_vm._v(_vm._s((_vm$summary8 = _vm.summary) === null || _vm$summary8 === void 0 ? void 0 : (_vm$summary8$raw_data = _vm$summary8.raw_data) === null || _vm$summary8$raw_data === void 0 ? void 0 : _vm$summary8$raw_data.no_pkk_tug_boat))])])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Diterbitkan Oleh ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_c('strong', [_vm._v(_vm._s((_vm$summary9 = _vm.summary) === null || _vm$summary9 === void 0 ? void 0 : (_vm$summary9$raw_data = _vm$summary9.raw_data) === null || _vm$summary9$raw_data === void 0 ? void 0 : _vm$summary9$raw_data.user.full_name))]), _vm._v(" From "), _c('strong', [_vm._v(_vm._s((_vm$summary10 = _vm.summary) === null || _vm$summary10 === void 0 ? void 0 : (_vm$summary10$raw_dat = _vm$summary10.raw_data) === null || _vm$summary10$raw_dat === void 0 ? void 0 : _vm$summary10$raw_dat.user.perusahaan))]), _vm._v(" AT "), _c('strong', [_vm._v(_vm._s(_vm.formatDateTime((_vm$summary11 = _vm.summary) === null || _vm$summary11 === void 0 ? void 0 : (_vm$summary11$raw_dat = _vm$summary11.raw_data) === null || _vm$summary11$raw_dat === void 0 ? void 0 : _vm$summary11$raw_dat.user.created_at)))])])])])], 1) : _vm._e(), _c('b-card', [_c('b-form-group', {
    attrs: {
      "label": "Rencana / Uraian Kegiatan",
      "label-for": "lbl-Rencana Uraian Kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Uraian Kegiatan",
      "vid": "Rencana Uraian Kegiatan"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrelloIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Rencana Uraian Kegiatan",
            "state": errors.length > 0 ? false : null,
            "placeholder": ''
          },
          model: {
            value: _vm.form.rencana_uraian_keg,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "rencana_uraian_keg", $$v);
            },
            expression: "form.rencana_uraian_keg"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('h3', [_vm._v("Upload Document File | Manifest")]), _c('small', [_vm._v("max size 10 MB ( xls, xlsx, pdf , jpeg , jpg , png )")]), _vm.fetchLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('DropZoneUploadJOPS', {
    key: _vm.isHaveFile.length,
    attrs: {
      "uri": _vm.BACKEND_URL,
      "update-i-d": _vm.dataID,
      "accept-file": "application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx",
      "max-files": 10,
      "re-upload-title": "Re Upload Pengajuan Jasa OPS  (Max 10 File Excel)",
      "is-have-file": _vm.isHaveFile,
      "modelName": 'jops_pengajuan_uploads',
      "info": "Maksimal 10 Berkas",
      "disable-action": true
    },
    on: {
      "callBack": _vm.dropZoneCallBack
    }
  })], 1), _vm.files.length !== 0 ? _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "block": "",
      "variant": "gradient-primary",
      "disabled": _vm.fetchLoading
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmit();
      }
    }
  }, [!_vm.fetchLoading ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }) : _vm._e(), _vm.fetchLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Terbitkan Form Jasa Pelayanan')
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }